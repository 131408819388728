import {
  PRODOTTO_DIGITALE,
  PRODOTTO_ABBONAMENTO,
  PRODOTTO_SPEDITO,
  CODICE_IVA_IO_PIU,
  PRODOTTO_DROPSHIPPING,
} from 'utils'

export function prodottiTipo(items, tipo) {
  return items?.filter((i) => i.prodotto?.tipo === tipo) || []
}

export function prodottiCodiceIva(items, codiceIva) {
  return items?.filter((i) => i.prodotto?.codiceIva === codiceIva) || []
}

export function prodottiSpediti(items) {
  return prodottiTipo(items, PRODOTTO_SPEDITO)
}

export function prodottiAbbonamento(items) {
  return prodottiTipo(items, PRODOTTO_ABBONAMENTO).filter(
    (i) => i.prodotto?.codiceIva !== CODICE_IVA_IO_PIU
  )
}

export function prodottiDropshipping(items) {
  return prodottiTipo(items, PRODOTTO_DROPSHIPPING)
}

export function prodottiDigitali(items) {
  return prodottiTipo(items, PRODOTTO_DIGITALE)
    .concat(prodottiCodiceIva(items, CODICE_IVA_IO_PIU))
    .filter(
      (i) => i.prodotto?.tipo !== PRODOTTO_DROPSHIPPING && i.prodotto?.tipo !== PRODOTTO_SPEDITO
    )
}

export function isIoPiu(prodotto) {
  return prodotto.codiceIva === CODICE_IVA_IO_PIU && prodotto.tipo === PRODOTTO_ABBONAMENTO
}

export const CART_SECTIONS = [
  {
    titolo: 'Prodotti da spedire',
    tipo: PRODOTTO_SPEDITO,
    helper: '',
  },
  {
    titolo: 'Prodotti in abbonamento',
    tipo: PRODOTTO_ABBONAMENTO,
    helper: "Riceverai la rivista all'indirizzo specificato in fase di checkout",
  },
  {
    titolo: 'Prodotti digitali',
    tipo: PRODOTTO_DIGITALE,
    helper: '',
  },
  {
    titolo: 'Prodotti Dropshipping',
    tipo: PRODOTTO_DROPSHIPPING,
    helper: '',
  },
]

export const LIMITE_FREE_SHIPPING = 19

export function getPromoAdapted(context) {
  if (!context) return null
  const adaptedContext = { ...context } // Create a new object and copy properties
  adaptedContext.items = adaptedContext.items.map((i) => ({ ...i, ...i.cartRow })) // Modify the 'items' property in the new object
  return adaptedContext
}
